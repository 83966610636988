export default {
  "oceanbase-playground.src.pages.OceanBaseWithFlink.CarOrderSystemDemo": "汽车下单 Demo",
  "oceanbase-playground.src.pages.OceanBaseWithFlink.Username": "用户名",
  "oceanbase-playground.src.pages.OceanBaseWithFlink.PleaseEnterUsername": "请输入用户名",
  "oceanbase-playground.src.pages.OceanBaseWithFlink.GenerateRandomly": "随机生成",
  "oceanbase-playground.src.pages.OceanBaseWithFlink.OrderCount": "预定量",
  "oceanbase-playground.src.pages.OceanBaseWithFlink.OrderColor": "颜色",
  "oceanbase-playground.src.pages.OceanBaseWithFlink.OrderSubmit": "预定下单",
  "oceanbase-playground.src.pages.OceanBaseWithFlink.DataVisualization": "数据可视化",
  "oceanbase-playground.src.pages.OceanBaseWithFlink.TotalOrderCount": "总预定量",
  "oceanbase-playground.src.pages.OceanBaseWithFlink.SqlLatency": "SQL 耗时：{latency}ms",
  "oceanbase-playground.src.pages.OceanBaseWithFlink.Top3ColorsOfToday": "今日颜色预定量 TOP 3",
  "oceanbase-playground.src.pages.OceanBaseWithFlink.OrderCountOfToday": "今日预定量",
  "oceanbase-playground.src.pages.OceanBaseWithFlink.RealTimeOrders": "实时订单",
  "oceanbase-playground.src.pages.OceanBaseWithFlink.OrderSuccess": "下单成功",
  "oceanbase-playground.src.pages.OceanBaseWithFlink.RealTimeOrderSuccess": "{customerName} 下单成功",
  "oceanbase-playground.src.pages.OceanBaseWithFlink.RealTimeCarColor": "车辆颜色：{color}",
  "oceanbase-playground.src.pages.OceanBaseWithFlink.Blue": "蓝色",
  "oceanbase-playground.src.pages.OceanBaseWithFlink.Black": "黑色",
  "oceanbase-playground.src.pages.OceanBaseWithFlink.Yellow": "橙色",
  "oceanbase-playground.src.pages.OceanBaseWithFlink.Red": "红色",
  "oceanbase-playground.src.pages.OceanBaseWithFlink.White": "白色",
  "oceanbase-playground.src.pages.OceanBaseWithFlink.Green": "绿色",
  "oceanbase-playground.src.pages.OceanBaseWithFlink.Purple": "紫色",
  "oceanbase-playground.src.pages.OceanBaseWithFlink.Gray": "灰色",
  "oceanbase-playground.src.pages.OceanBaseWithFlink.Cyan": "青蓝色",
  "oceanbase-playground.src.pages.OceanBaseWithFlink.Count1": "1 辆",
  "oceanbase-playground.src.pages.OceanBaseWithFlink.Count10": "瞬间连续下单 10 辆",
  "oceanbase-playground.src.pages.OceanBaseWithFlink.Count100": "瞬间连续下单 100 辆",
  "oceanbase-playground.src.pages.OceanBaseWithFlink.Count1000": "瞬间连续下单 1000 辆",
  "oceanbase-playground.src.pages.OceanBaseWithFlink.Count10000": "瞬间连续下单 10000 辆",
  "oceanbase-playground.src.pages.OceanBaseWithFlink.ScanQrCodeToTry": "快来扫码试试吧！",
  "oceanbase-playground.src.pages.OceanBaseWithFlink.SupportMultiplePeopleOrder": "支持多人同时下单",
};
